import React from "react";
import { HomePage } from "../pages/home.page";
import { LocationsPage } from "../pages/location.page";
import { AddressBookPage } from "../pages/address.page";
import { NewTripPage } from "../pages/trip.page";
import { TripsPage } from '../pages/edit-trips.page';
import { AgentsPage } from '../pages/agents.page';
import { GstPage } from '../pages/gst.page';
import { VechicleManagementPage } from '../pages/vechicle-management.page';
import { VechicleAllocationPage } from '../pages/vechicle-allocation.page';
import { PriceListPage } from '../pages/price-list.page';
import { MasterPriceListPage } from '../pages/master-price-list.page';
import {  UserPrivillegePage } from '../pages/user-privillege-page';
import { BusPage } from '../pages/bus.page';
import { ErrorPage } from "../pages/error.page";
import { BusOperatorsPage } from "../pages/bus-operator.page.js";
import { AgentsColorPage } from "../pages/agents-color.page";
import { TravelManagementPage } from "../pages/travel-management.page";
import { SpalshPage } from "./../pages/spalsh.page";
import { NavMenu } from "./../components/header/navbar/navbar.component";
import { BrowserRouter as Router, Route , Routes} from 'react-router-dom';
import  {PrivateRoute}  from './private-route';
import { LoginPage } from "../pages/login.page";
import { loginReducerSelector } from '../selectors/login-selectors';
import { useSelector } from 'react-redux';
import { DemandDaysPage } from "../pages/demand-days.page";
import { AdminDriverMappingPage } from "../pages/admin-driver-mapping.page";

export const RouteRoot = () => {

    const {
        isLoggedIn 
      } = useSelector(loginReducerSelector);
      
    return (
        <Router>
                <NavMenu />
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<SpalshPage />} />
                    <Route path="/cities" element={<PrivateRoute isLoggedIn={isLoggedIn}><LocationsPage /></PrivateRoute>} />
                    <Route path="/gst" element={<PrivateRoute isLoggedIn={isLoggedIn}><GstPage /></PrivateRoute>} />
                    <Route path="/address" element={<PrivateRoute isLoggedIn={isLoggedIn}><AddressBookPage /></PrivateRoute>} />
                    <Route path="/newtrip" element={<PrivateRoute isLoggedIn={isLoggedIn}><NewTripPage /></PrivateRoute>} />
                    <Route path="/trips" element={<PrivateRoute isLoggedIn={isLoggedIn}><TripsPage /></PrivateRoute>} />
                    <Route path="/agents" element={<PrivateRoute isLoggedIn={isLoggedIn}><AgentsPage /></PrivateRoute>} />
                    <Route path="/agent-portal-colors" element={<PrivateRoute isLoggedIn={isLoggedIn}><AgentsColorPage /></PrivateRoute>} />
                    <Route path="/vechicle-managemment" element={<PrivateRoute isLoggedIn={isLoggedIn}><VechicleManagementPage /></PrivateRoute>} />
                    <Route path="/vechicle-allocation" element={<PrivateRoute isLoggedIn={isLoggedIn}><VechicleAllocationPage /></PrivateRoute>} />
                    <Route path="/price-list" element={<PrivateRoute isLoggedIn={isLoggedIn}><PriceListPage /></PrivateRoute>} />
                    <Route path="/master-price-list" element={<PrivateRoute isLoggedIn={isLoggedIn}><MasterPriceListPage /></PrivateRoute>} />
                    <Route path="/user-privillege" element={<PrivateRoute isLoggedIn={isLoggedIn}><UserPrivillegePage /></PrivateRoute>} />
                    <Route path="/bus-operators" element={<PrivateRoute isLoggedIn={isLoggedIn}><BusOperatorsPage /></PrivateRoute>} />
                    <Route path="/buses" element={<PrivateRoute isLoggedIn={isLoggedIn}><BusPage /></PrivateRoute>} />
                    <Route path="/travel-management" element={<PrivateRoute isLoggedIn={isLoggedIn}><TravelManagementPage /></PrivateRoute>} />
                    <Route path="/demand-days" element={<PrivateRoute isLoggedIn={isLoggedIn}><DemandDaysPage /></PrivateRoute>} />
                    <Route path="/admin-driver-mapping" element={<PrivateRoute isLoggedIn={isLoggedIn}><AdminDriverMappingPage /></PrivateRoute>} />
                    <Route path="/error" element={<PrivateRoute isLoggedIn={isLoggedIn}><ErrorPage /></PrivateRoute>} />
                    <Route path="/home" element={<PrivateRoute isLoggedIn={isLoggedIn}><HomePage /></PrivateRoute>} />
                </Routes>
        </Router>
    );
} 