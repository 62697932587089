import { Form , Input, Button , Select , Typography , Layout, Row , Col, Checkbox, InputNumber} from 'antd';
import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAgent , deleteAgent , updateAgent , getAgentsDetails}from '../../../actions/agent-actions';
import { isValid, isValidBool  } from '../../../utils/utilities';
import { showConfirm, generatePassword } from '../../../utils/utilities';
// var randomstring = require("randomstring");
import{ loginReducerSelector } from '../../../selectors/login-selectors';
import { POST_PAID_USER, PRE_PAID_USER } from '../../../utils/constant';



const { Option } = Select;

const { Content, Footer } = Layout;
const { Title , Text } = Typography;


export const  AgentForm = (props) => {

  const{
    userInfo
  } = useSelector(loginReducerSelector);

  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

      const{
        formData,
        updateformData,
        agentPrivilieges,
        operatorCode       
      } = {...props};

  let defaultPostFixUserName = "-"+operatorCode;
      
      const [agentType,setAgentType]=useState(null);
      // const [isCommissionManditory,setIsCommissionManditory]=useState(false);

      const getAgentsPoints=()=>{
        dispatch(getAgentsDetails(operatorCode));
      }
      
      const onSuccess=()=>{
        getAgentsPoints();
        onReset();
      }
      
      const onReset = () => {
        updateformData({});
        setAgentType(null);
      };

 const handleSubmit = (e,buttonType) => {
    e.preventDefault();
    form.validateFields()
    .then(values => {
      let {
        acCommission,
        acCommissionType,
        commissionType,
        hiTechCommission,
        hitechCommissionType,
        location,
        name,
        nonAcCommission,
        password,
        phone_number,
        privilege,
        user_name,
        agent_type,
        cancellation_penality_percentage,
        verify_otp_for_login,
        discount_percentage,
        discount_value
      } = values;

      if(!isShowPrePaidOption){
        agent_type=null;
      }
      
      let formateData={
          name:name,
          location:location,
          password:password,
          phone_number:phone_number,
          privilege:privilege,
          user_name:user_name,
          agent_type:agent_type,
          verify_otp_for_login: verify_otp_for_login,
          cancellation_penality_percentage,
          discount_percentage:discount_percentage,
          discount_value:discount_value
      };
      
        if(isValid(acCommissionType)&&acCommissionType=="%"){
          formateData.ac_commission=0
          formateData.ac_commission_percentage=acCommission;
        }else{
          formateData.ac_commission=acCommission;
          formateData.ac_commission_percentage=0;
        }
        if(isValid(hitechCommissionType)&&hitechCommissionType=="%"){
          formateData.hitech_commission=0;
          formateData.hitech_commission_percentage=hiTechCommission;
        }else{
          formateData.hitech_commission=hiTechCommission;
          formateData.hitech_commission_percentage=0;
        }
        if(isValid(commissionType)&&commissionType=="%"){
          formateData.commission=0;
          formateData.commission_percentage=nonAcCommission;
        }else{
          formateData.commission=nonAcCommission;
          formateData.commission_percentage=0;
        }
      
      formateData.operator_code=userInfo.operator_code;

      if(buttonType=='add'){
        formateData.user_name=formateData.user_name + defaultPostFixUserName;
        dispatch(createAgent({...formateData,operatorCode},onSuccess));
      }else if(buttonType="update"){
        formateData.id=formData.id;
        console.log("formatData", formateData)
       dispatch(updateAgent({...formateData,operatorCode},onSuccess))
      }
    })
    .catch(errorInfo => {
      console.log({errorInfo});
    })
 };

 const handleGeneratePassword=()=>{
  form.setFieldsValue({
   password:generatePassword(10)
 })
}

 useEffect(()=>{
    fillForm()
    if (isValid(formData)&&isValidBool(formData.show_password)){
      setShowPassword(formData.show_password);
    }
    else {
      setShowPassword(true);
    }
 },[formData])

 const fillForm=()=>{
      if(isValid(formData)){
        let {
          ac_commission,
          ac_commission_percentage,
          access_code,
          commission,
          commission_percentage,
          encrypted_password,
          hitech_commission,
          hitech_commission_percentage,
          location,
          name,
          password,
          phone_number,
          privilege,
          user_name,
          website_commission,
          website_commission_percentage,
          agent_type,
          cancellation_penality_percentage,
          verify_otp_for_login,
          discount_percentage,
          discount_value
        }=formData;
        let commissionType=null,acCommissionType=null,hitechCommissionType=null,
            nonAcCommission=null,acCommission=null,hiTechCommission=null;
        if(commission>=1||(commission==0&&(commission_percentage<0||!isValid(commission_percentage)))){
          commissionType='₹';
          nonAcCommission=commission;
        }else if (commission_percentage>=0) {
          commissionType='%';
          nonAcCommission=commission_percentage
        }
        if(ac_commission>=1||(ac_commission==0&&(ac_commission_percentage<0||!isValid(ac_commission_percentage)))){
          acCommissionType="₹";
          acCommission=ac_commission;
        }else if(ac_commission_percentage>=0){
          acCommissionType="%";
          acCommission=ac_commission_percentage;
        }

        if (!isValid(verify_otp_for_login)){
          verify_otp_for_login=false;
        }

        if(hitech_commission>=1||(hitech_commission==0&&(hitech_commission_percentage<0||!isValid(hitech_commission_percentage)))){
          hitechCommissionType="₹";
          hiTechCommission=hitech_commission;
        }else if(hitech_commission_percentage>=0){
          hitechCommissionType="%";
          hiTechCommission=hitech_commission_percentage;
        }
          form.setFieldsValue({
            ac_commission: ac_commission,
            ac_commission_percentage: ac_commission_percentage,
            access_code: access_code,
            nonAcCommission: nonAcCommission,
            commission_percentage: commission_percentage,
            encrypted_password: encrypted_password,
            hitech_commission: hitech_commission,
            hitech_commission_percentage: hitech_commission_percentage,
            location: location,
            name: name,
            password: password,
            phone_number: phone_number,
            privilege: privilege,
            user_name: user_name,
            website_commission: website_commission,
            website_commission_percentage: website_commission_percentage,
            acCommissionType:acCommissionType,
            commissionType:commissionType,
            hitechCommissionType:hitechCommissionType,
            acCommission:acCommission,
            hiTechCommission:hiTechCommission,
            agent_type:agent_type,
            cancellation_penality_percentage:cancellation_penality_percentage,
            verify_otp_for_login: verify_otp_for_login,
            discount_percentage : discount_percentage,
            discount_value : discount_value
          })
          setAgentType(privilege);
      }else{
        form.resetFields();
      }
 }


const deleteAction=()=>{
  showConfirm(
      ()=>{
          dispatch(deleteAgent(formData.id,operatorCode,onSuccess));
      },
      '',
      ''
    )
}

    let isRowClicked = isValid(formData)&&isValid(formData.id)?true:false;
    let isCommissionManditory = isValid(agentType)&&(agentType=="Booking Agent"||agentType==="Agent BT")?true:false;
    let isShowPrePaidOption = isValid(agentType)&&(agentType=="Booking Agent"||agentType==="Agent BT"||agentType==="Agent")?true:false;

    const printOptionPrivilege = (option) => {
      return <Option  value={option.privilege}>{option.privilege}</Option>
    };
    return (
      <Layout>
            <Title level={4}>Active Office/Agents Form</Title>
              <Content>
                        <Form 
                             form={form} 
                             name="basic"
                             initialValues={{cancellation_penality_percentage:0, verify_otp_for_login: false }}
                             className="form"
                        >
                            <Row>
                              <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item
                                                label="Name"
                                                name="name"
                                                rules={[{ required: true, message: 'Please input your name!' }]}
                                            >
                                                <Input 
                                                placeholder="Name"
                                                />
                                        </Form.Item>
                              </Col>
                              <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item
                                                label="Type"
                                                name="privilege"
                                                rules={[{ required: true, message: 'Please input your type!' }]}
                                            >
                                                  <Select
                                                     showSearch
                                                     width={200}
                                                     placeholder="Select type"
                                                     onChange={(data)=>{
                                                       setAgentType(data);
                                                     }}
                                                  >
                                                    {agentPrivilieges.map(printOptionPrivilege)}
                                                </Select>
                                        </Form.Item>
                              </Col>
                              <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item
                                                label="Location"
                                                name="location"
                                                rules={[{ required: false, message: 'Please input your location!' }]}
                                            >
                                                <Input 
                                                placeholder="Location"
                                                />
                                        </Form.Item>
                              </Col>
                              <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item
                                                label="Phone No"
                                                name="phone_number"
                                                rules={[{ required: true, message: 'Please input your phone no!' }]}
                                            >
                                                <Input 
                                                placeholder="Phone no"
                                                />
                                        </Form.Item>
                                </Col>
                                </Row>
                                <Row>
                                <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item
                                                label="User Name"
                                                name="user_name"
                                                rules={[{ required: true, message: 'Please input your user name!' }]}
                                            >
                                                <Input 
                                                 disabled={isRowClicked}
                                                 placeholder="User name"
                                                 addonAfter={isRowClicked?null:defaultPostFixUserName}
                                                />
                                        </Form.Item>
                                 </Col>
                                 <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[{ required: false, message: 'Please input your password!' }]}
                                            >
                                                <Input 
                                                  type={showPassword?"text":"password"}
                                                  placeholder="Password"
                                                  disabled={showPassword?false:true}
                                                />
                                        </Form.Item>
                                 </Col>
                                 <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                        <Form.Item>
                                              <Button 
                                              disabled={showPassword?false:true}
                                              type="danger" onClick={handleGeneratePassword}>Generate password</Button>
                                        </Form.Item>
                                  </Col>
                                  <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                    {isShowPrePaidOption&&
                                      <Form.Item
                                                  label="Agent type"
                                                  name="agent_type"
                                                  rules={[{ required: false, message: 'Please input your agent type!' }]}
                                              >
                                                <Select 
                                                  showSearch
                                                  width={60}
                                                  placeholder="Select agent type"
                                                  >
                                                      <Option value={null}></Option>
                                                      <Option value={POST_PAID_USER}>Post-paid</Option>
                                                      <Option value={PRE_PAID_USER}>Pre-paid</Option>
                                                  </Select> 
                                          </Form.Item>
                                    }  
                                  </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}> 
                                        <Form.Item
                                                label="Cancellation penality %"
                                                name="cancellation_penality_percentage"
                                                rules={[{ required: false, message: 'Please input your cancellation penality %!' }]}
                                            >
                                               <InputNumber placeholder='Cancellation penality %'/> 
                                        </Form.Item>
                                    </Col>
                                     <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                      <Form.Item
                                        label="Discount percentage"
                                        name="discount_percentage"
                                        rules={[{ required: false, message: 'Please input your discount percentage!' }]}
                                      >
                                        <InputNumber placeholder='Discount %'/>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                      <Form.Item
                                        label="Discount value"
                                        name="discount_value"
                                        rules={[{ required: false, message: 'Please input your discount value!' }]}
                                      >
                                        <InputNumber placeholder='₹'/>
                                      </Form.Item>
                                    </Col>

                                    <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                      <Form.Item
                                        valuePropName="checked"
                                        label="Verify OTP for Login"
                                        name="verify_otp_for_login"
                                        rules={[{ required: false, message: 'Please input your Verify OTP for Login!' }]}
                                      >
                                        <Checkbox />
                                      </Form.Item>
                                    </Col>
                                </Row>
                                          <Row> 
                                            <Col xs={{ span: 24}}  sm={{span:24 }} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                              <Text strong> Commision</Text>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs={{ span: 10 , offset: 1}}  sm={{span:10 , offset: 1}} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                            <Form.Item
                                                    label="Non AC buses(Value)"
                                                    name="nonAcCommission"
                                                    rules={[{ required: isCommissionManditory ,message: ''}]}
                                                >
                                                    <Input />
                                            </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 10 , offset: 1}}  sm={{span:10 , offset: 1}} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                              <Form.Item
                                                      label="Commission Type"
                                                      name="commissionType"
                                                      rules={[{ required: isCommissionManditory , message: ''}]}
                                                  >
                                                      <Select 
                                                      showSearch
                                                      width={100}
                                                      placeholder="Select"
                                                      >
                                                          <Option value={"₹"}>₹</Option>
                                                          <Option value={"%"}>%</Option>
                                                      </Select> 
                                                  
                                              </Form.Item>
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col xs={{ span: 10 , offset: 1}}  sm={{span:10 , offset: 1}} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                                  <Form.Item
                                                        label="AC buses(Value)"
                                                        name="acCommission"
                                                        rules={[{ required: isCommissionManditory , message: ''}]}
                                                    >
                                                        <Input 
                                                      
                                                        />
                                                </Form.Item>
                                              </Col>
                                              <Col xs={{ span: 10 , offset: 1}}  sm={{span:10 , offset: 1}} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                                  <Form.Item
                                                        label="Commission Type"
                                                        name="acCommissionType"
                                                        rules={[{ required: isCommissionManditory , message: ''}]}
                                                    >
                                                        <Select 
                                                            showSearch
                                                            width={100}
                                                            placeholder="Select">

                                                            <Option value={"₹"}>₹</Option>
                                                            <Option value={"%"}>%</Option>
                                                        </Select> 
                                                    
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                            <Row>
                                            <Col xs={{ span: 10 , offset: 1}}  sm={{span:10 , offset: 1}} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                                      <Form.Item
                                                            label="Scania/Volvo(Value)"
                                                            name="hiTechCommission"
                                                            rules={[{ required: isCommissionManditory,message: '' }]}
                                                        >
                                                            <Input 
                                                              
                                                            />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col xs={{ span: 10 , offset: 1}}  sm={{span:10 , offset: 1}} md={{span: 5, offset: 1}} lg={{ span: 5 }}>
                                                    <Form.Item
                                                        label="Commssion Type"
                                                        name="hitechCommissionType"
                                                        rules={[{ required: isCommissionManditory, message: '' }]}
                                                    >
                                                        <Select      
                                                            width={100}
                                                            showSearch
                                                            placeholder="Select">
                                                            <Option value={"₹"}>₹</Option>
                                                            <Option value={"%"}>%</Option>

                                                        </Select> 
                                                    
                                                </Form.Item>
                                                </Col>
                                              </Row>                                  
                  </Form>
              </Content>
              <Footer style={{ textAlign: 'center' }} type="flex">
              <Row type="flex" justify="space-around">
                      <Button
                        style={{margin:'5px',background:'#2EC17C'}}
                        disabled={isRowClicked}
                        onClick={(e)=>{
                         handleSubmit(e,'add');
                        }}>
                          <Text>Add</Text>
                      </Button>
                      <Button type="primary"
                        style={{margin:'5px'}}
                        
                       onClick={(e)=>{
                       handleSubmit(e,'update');
                       }}>
                        Update
                      </Button>
                        <Button type="danger"
                        style={{margin:'5px'}}
                        disabled={!isRowClicked}
                        onClick={deleteAction}>
                        Delete
                     </Button>
                      <Button type="block"
                        style={{margin:'5px'}}
                        onClick={()=>{
                            updateformData({});
                        }}>
                        Clear
                      </Button>
                  </Row>
              </Footer>
      </Layout>
    );
}
